const monitoringTranslation = {
  mode: 'ko',
  menu: '종합 모니터링',

  totalUpload: '총 업로드',
  currentStatus: '현상태',
  videoDevice: '비디오 장치',
  videoDeviceStatus: '비디오 장치 상태',
  activity: '활동',
  scan: '스캔',

  start: '프로그램시작',
  nosess: '대기중',
  session: '모션감지중',
  recording: '녹화중',
  paused: '일시정지',
  exit: '프로그램종료',

  lsHdml: '구캡',
  lsEasyCap: '이지캡',
  yuh01: '신캡',
  gameDockUltra: '게임독 울트라',
  yux13: '신신캡',
  asus: 'ASUS캡',
  unknown: '알수없음',

  hdmlYuyv422: 'HDMI/YUYV422',
  hdmlMjpeg: 'HDMI/MJPEG',
  analog: '아날로그',
  dark: '어두움',
  error: 'ERROR',

  pingAll: '전체 핑 보내기',
  sendRemoteCommand: '원격 명령 전송',
  restartApp: '앱 재시작',
  stopRecording: '녹화 중지',
  restartAppV3: 'V3 앱 재시작',
  rebootDeviceV3: 'V3 장비 재부팅',
  shutdownDeviceV3: 'V3 장비 종료',
  rollbackDeviceV3: 'V3 장비 롤백',
  agent: '관리 에이전트',

  online: '온라인',
  recordStatus: '녹화 중',
  uploadStatus: '업로드 중',

  logInquiry: '로그 조회하기',
  syncLog: '로그 반영하기',
  ping: '핑 보내기',
  more: '더 보기',

  deviceLogExplorer: '디바이스 로그 탐색기',
  selectLogDate: '조회할 로그 날짜 선택',
  totalLog: '전체 로그',
  logByBarcode: '바코드별 로그',
  barcode: '바코드',
  scannedAt: '스캔 시간',
  fileId: '파일 ID',
  scanInfo: '스캔 정보',
  detail: '상세보기',
  motionInfo: '모션 정보',
  recordInfo: '녹화 정보',
  uploadInfo: '업로드 정보',
  segment: '분할',
  startTime: '시작시간',
  endTime: '종료시간',
  duration: '길이',
  success: '완료',
  fail: '실패',
  recordingCheck: '영상확인',
  motionCheck: '모션확인',
  recordingsDownload: '다운로드',
  download: '다운로드',

  failedUploadRecordingDownload: '업로드 실패 초음파 영상 or 모션 영상 다운로드',
  downloadTitle: '다운로드 확인',
  customCropTitle: '커스텀 크롭 설정',
  remoteCommandTitle: '디바이스 원격 명령 전송',

  name: '이름',
  open: 'Open',
  close: 'Close',
  version: '버전',
  update: '업데이트',
  disconnect: '연결 종료',
  connect: '연결 생성',
  versionPlaceholder: '마미박스 버전 선택',

  remoteScan: '원격 스캔',
  resolution: '해상도 설정',
  crop: '크롭 설정',
  customCrop: '커스텀 크롭 설정',
  category: '유형',
  value: '값',

  selectHospitalMessage: '병원을 선택해 주세요.',
  cropMessage: '크롭 설정을 변경하시겠습니까?',
  customCropMessage: '커스텀 크롭 설정으로 적용하시겠습니까?',
  syncLogMessage: '로그를 반영하시겠습니까?',
  sendPingMessage: '핑을 보내시겠습니까?',
  stopRecordingMessage: '녹화를 중지하시겠습니까?',
  restartAppMessage: '앱을 재시작하시겠습니까?',
  resolutionMessage: '해상도를 변경하시겠습니까?',
  remoteScanMessage: '원격 스캔을 하시겠습니까?',
  showScreenshotMessage: '스크린샷을 보시겠습니까?',
  liveMessage: '라이브에 즉시 반영됩니다.',
  noLogMessage: '로그가 없습니다.',
  noEquipmentMessage: '장비를 찾을 수 없습니다.',
  noVideoMessage: '다운로드에 실패했습니다. 영상이 존재하지 않습니다.',
  failDownloadMessage: '다운로드에 실패했습니다. 다시 시도해 주세요.',
  failScreenshotMessage: '스크린샷을 요청하는데 실패했습니다. 이전에 저장된 스크린샷을 표시합니다.',
  downloadStartMessage: '다운로드를 클릭하세요',
  agentUpdateMessage: '에이전트 업데이트를 요청했습니다.',
  mommyBoxUpdateMessage1: '마미박스',
  mommyBoxUpdateMessage2: '업데이트를 요청했습니다.',
  sshOrderMessage: '연결을 요청했습니다.',
  sshOrderCloseMessage: '연결 종료를 요청했습니다.',
  rebootDeviceMessage: '장비를 재부팅 하시겠습니까?',
  shutdownDeviceMessage: '장비를 종료 하시겠습니까?',
  rollbackDeviceMessage: '마미박스 버전을 롤백 하시겠습니까?',
};

export default monitoringTranslation;
