const monitoringTranslation = {
  mode: 'en',
  menu: 'Device Monitoring',

  totalUpload: 'Total Upload',
  currentStatus: 'Status',
  videoDevice: 'Capture Card',
  videoDeviceStatus: 'Video Signal',
  activity: 'Last Activity',
  scan: 'Last Scan/Command',

  start: 'Program Start',
  nosess: 'Standby',
  session: 'Motion Detecting',
  recording: 'Recording',
  paused: 'Paused',
  exit: 'Program Exit',

  lsHdml: 'IsHdml',
  lsEasyCap: 'EasyCap',
  yuh01: 'YUH-01',
  gameDockUltra: 'GameDock Ultra',
  yux13: 'YUX13',
  asus: 'ASUS capturecard',
  unknown: 'Unknown',

  hdmlYuyv422: 'HDMI/YUYV422',
  hdmlMjpeg: 'HDMI/MJPEG',
  analog: 'Analog',
  dark: 'Dark',
  error: 'Error',

  pingAll: 'Ping all devices',
  sendRemoteCommand: 'Send command',
  restartApp: 'Restart Program',
  stopRecording: 'Stop Recording',
  restartAppV3: 'Restart V3 Program',
  rebootDeviceV3: 'Reboot V3 Device',
  shutdownDeviceV3: 'Shutdown V3 Device',
  rollbackDeviceV3: 'Rollback V3 Device',
  agent: 'Remote Access',

  online: 'Online',
  recordStatus: 'Recording',
  uploadStatus: 'Uploading',

  logInquiry: 'Check Log',
  syncLog: 'Update Log',
  ping: 'Ping',
  more: 'More',

  deviceLogExplorer: 'Log Explorer',
  selectLogDate: 'Select Log Date',
  totalLog: 'Total Log',
  logByBarcode: 'Log by Barcode',
  barcode: 'Barcode',
  scannedAt: 'Scanned At',
  fileId: 'File ID',
  scanInfo: 'Scan Info',
  detail: 'Detail',
  motionInfo: 'Motion Info',
  recordInfo: 'Recording Info',
  uploadInfo: 'Upload Info',
  segment: 'Segment',
  startTime: 'Start at',
  endTime: 'End at',
  duration: 'Duration',
  success: 'Success',
  fail: 'Failed',
  recordingCheck: 'Check recording',
  motionCheck: 'Check motion',
  recordingsDownload: 'Download recording',
  download: 'Download',

  failedUploadRecordingDownload: 'Failed Upload Recording or motion Download',
  downloadTitle: 'Download Confirmation',
  customCropTitle: 'Custom Screen Crop',
  remoteCommandTitle: 'Remote Setting',

  name: 'Device',
  open: 'Open',
  close: 'Close',
  version: 'Version',
  update: 'Update',
  disconnect: 'Disconnect',
  connect: 'Connect',
  versionPlaceholder: 'Select Version',

  remoteScan: 'Remote recording',
  resolution: 'Resolution Setting',
  crop: 'Screen Crop',
  customCrop: 'Custom Screen Crop',
  category: 'Command',
  value: 'Value',

  selectHospitalMessage: 'Please select an account.',
  cropMessage: 'Change the screen crop?',
  customCropMessage: 'Change the screen crop?',
  syncLogMessage: 'Update the logs?',
  sendPingMessage: 'Send a ping?',
  stopRecordingMessage: 'Stop recording?',
  restartAppMessage: 'Restart the program?',
  resolutionMessage: 'Change resolution?',
  remoteScanMessage: 'Proceed a remote scan?',
  showScreenshotMessage: 'View screenshot?',
  liveMessage: 'It will update instantly.',
  noLogMessage: 'No logs.',
  noEquipmentMessage: 'No devices found.',
  noVideoMessage: 'Error: Download failed. No video exists.',
  failDownloadMessage: 'Error: Download failed. Please try again.',
  failScreenshotMessage: 'Error: Failed to request a screenshot. Showing last saved screenshot.',
  downloadStartMessage: 'Click to download',
  agentUpdateMessage: 'Agent update requested.',
  mommyBoxUpdateMessage1: 'Device',
  mommyBoxUpdateMessage2: 'Update requested.',
  sshOrderMessage: 'Connection requested.',
  sshOrderCloseMessage: 'Disconnection requested.',
  rebootDeviceMessage: 'proceed to reboot the device?',
  shutdownDeviceMessage: 'proceed to shutdown the device?',
  rollbackDeviceMessage: 'proceed to rollback before version?',
};

export default monitoringTranslation;
